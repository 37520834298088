






import { Component, Vue } from "vue-property-decorator";
import vesselAdminWrapper from "@/components/knockoutWrappers/VesselAdministrationWrapper.vue";
import events from "App/events";
import { SnackbarConfig } from "@/types/snackbarConfig";
import htmlEncoder from "Utilities/htmlencode-helper";

@Component({
  components: {
    vesselAdminWrapper,
  },
})
export default class VesselAdmin extends Vue {
  vesselAdministrationUpdatedBinding: any = null;
  vesselDeletedBinding: any = null;

  message(action: string, vesselName: string): Partial<SnackbarConfig> {
    let text = `Vessel '${htmlEncoder.htmlEncode(vesselName)}' has been `;
    switch (action) {
      case "createVessel":
        text += "<b>created</b>";
        break;

      case "editVessel":
      case "assignVessel":
        text += "<b>updated</b>";
        break;

      case "archiveVessel":
        text += "<b>archived</b>";
        break;

      case "activateVessel":
        text += "<b>activated</b>";
        break;

      case "deactivateVessel":
        text += "<b>deactivated</b>";
        break;

      case "deleteVessel":
        text += "<b>deleted</b>";
        break;

      case "setAsDataReceiver":
        text += "<b>set as data receiver</b>";
        break;

      case "unsetAsDataReceiver":
        text += "<b>unset as data receiver</b>";
        break;
    }

    return {
      text: text,
      color: "success",
    };
  }

  mounted(): void {
    this.vesselAdministrationUpdatedBinding = events.vesselAdministrationUpdated.add((action: string, vesselName: string) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message(action, vesselName));
    });

    this.vesselDeletedBinding = events.vesselDeleted.add((action: string, vesselName: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", this.message(action, vesselName));
    });
  }

  beforeDestroy(): void {
    this.vesselAdministrationUpdatedBinding.detach();
    this.vesselDeletedBinding.detach();
  }
}
